import React, { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import bronty from "../../images/6_bronty_no_bg.png";

const CustomNavbar = ({ isLoggedIn, user, onLogout }) => {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      if (window.scrollY > 0) {
        navbar.style.boxShadow = "0 4px 2px -2px gray";
      } else {
        navbar.style.boxShadow = "none";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let user_level = 1;
  let user_points = 0;
  let user_coins = 0;

  if (user && user.email && (user.email.includes("@unal.edu.co") || user.email.includes("@udistrital.edu.co") || user.email.includes("@javeriana.edu.co"))) {
    user_level = 3;
  }

  return (
    <Navbar
      bg="light"
      expand="lg"
      className="navbar"
      style={{ width: "100%", position: "fixed", top: 0, zIndex: 1001, transition: "box-shadow 0.3s ease-in-out" }}
    >
      <a href="/">
        <img
          src={bronty}
          alt="Bronty"
          style={{ width: "10%", minWidth: "30px", maxWidth: "40px", height: "auto", margin: "0px auto" }}
        />
      </a>
      <Nav className="mr-auto d-flex flex-row">
        <Nav.Link href="/" className="ml-2" style={{ textDecoration: "underline" }}>¡Búsqueda!</Nav.Link>
        <Nav.Link href="/dashboard/" className="ml-2" style={{ textDecoration: "underline" }}>¡Ofrecer!</Nav.Link>
      </Nav>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          {isLoggedIn ? (
            <>
              <Navbar.Text>
                [ Usuario: <b class="skiptranslate">{user.username}</b> ]
              </Navbar.Text>
              {/*<Navbar.Text>
                [ Email: <b>{user.email}</b> ]
              </Navbar.Text>*/}
              <Navbar.Text>
                [ Nivel 🏆: <b>{user_level}</b> ]
              </Navbar.Text>
              <Navbar.Text>
                [ Puntos ⭐: <b>{user_points}</b> ]
              </Navbar.Text>
              <Navbar.Text>
                [  Monedas 💳: <b>{user_coins}</b> ]
              </Navbar.Text>
              <Nav.Link onClick={onLogout} style={{ textDecoration: "underline" }}>Cerrar Sesión.</Nav.Link>
            </>
          ) : (
            <>
              <Nav.Link as={Link} to="/login/" style={{ textDecoration: "underline" }}>Acceder</Nav.Link>
              <Nav.Link as={Link} to="/signup/" style={{ textDecoration: "underline" }}>Crear Cuenta.</Nav.Link>
            </>
          )}
          <Nav.Link href="/dashboard/" style={{ textDecoration: "underline" }}>¡Publica GRATIS!</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

CustomNavbar.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default CustomNavbar;
